import { CpfCnpjField } from '@/components/Form/Fields'
import { useEffect, useState } from 'react'
import { CNPJ, CPF } from '../constants'

export const verifyRecipeintDoc = formValidation => {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (!formValidation.values.recipientDocument || formValidation.values.recipientDocument < 0) {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }, [formValidation.values.recipientDocument])

  switch (Number(formValidation.values.recipientDocument)) {
    case CPF:
      return (
        <CpfCnpjField
          name='recipientCpfCnpj'
          values={formValidation.values.recipientCpfCnpj}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.recipientCpfCnpj}
          label='CPF do Titular'
          className='w-full'
          noMargins
          placeholder='Ex.: 123.456.789-01'
          isDisabled={disabled}
        />
      )
    case CNPJ:
      return (
        <CpfCnpjField
          name='recipientCpfCnpj'
          format='cnpj'
          values={formValidation.values.recipientCpfCnpj}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.recipientCpfCnpj}
          label='CNPJ do Titular'
          className='w-full'
          noMargins
          placeholder='Ex.: 12.345.678/0001-90'
          isDisabled={disabled}
        />
      )
    default:
      return (
        <CpfCnpjField
          name='recipientCpfCnpj'
          values={formValidation.values.recipientCpfCnpj}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.recipientCpfCnpj}
          label='CPF do Titular'
          className='w-full'
          noMargins
          placeholder='Ex.: 123.456.789-01'
          isDisabled={disabled}
        />
      )
  }
}
