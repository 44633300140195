import { useApi } from '@/hooks'
import { getEndpoint } from '@/utils/endpoints'
import httpClient from '@/utils/httpClient'

export function useGetWithdrawsAdmin() {
  const { data, error, loading, meta, makeRequest } = useApi(params => {
    const { method, route } = getEndpoint('fetchWithdrawalsAdmin')
    return httpClient[method](`${route}?sort=createdAt`, { params })
  })

  return {
    withdrawalData: data,
    withdrawalMeta: meta,
    withdrawalError: error,
    withdrawalLoading: loading,
    getWithdrawals: makeRequest,
  }
}

export function useGetWithdrawDetailAdmin() {
  const { data, error, loading, meta, makeRequest } = useApi(id => {
    const { method, route } = getEndpoint('fetchWithdrawalDetailAdmin', { id })
    return httpClient[method](route)
  })

  return {
    withdrawalDetailData: data,
    withdrawalDetailMeta: meta,
    withdrawalDetailError: error,
    withdrawalDetailLoading: loading,
    getWithdrawalDetail: makeRequest,
  }
}

export function useApproveWithdrawAdmin() {
  const { data, error, loading, meta, makeRequest } = useApi(id => {
    const { method, route } = getEndpoint('approveWithdrawalAdmin', { id })
    return httpClient[method](route)
  })

  return {
    withdrawalApproveData: data,
    withdrawalApproveMeta: meta,
    withdrawalApproveError: error,
    withdrawalApproveLoading: loading,
    postWithdrawalApprove: makeRequest,
  }
}

export function useRefuseWithdrawAdmin() {
  const { data, error, loading, meta, makeRequest } = useApi(({ id, rejectionReason }) => {
    const { method, route } = getEndpoint('rejectWithdrawalAdmin', { id })
    return httpClient[method](route, { rejectionReason })
  })

  return {
    withdrawalRefuseData: data,
    withdrawalRefuseMeta: meta,
    withdrawalRefuseError: error,
    withdrawalRefuseLoading: loading,
    postWithdrawalRefuse: makeRequest,
  }
}

export function useGetBalance() {
  const { data, error, loading, makeRequest } = useApi(() => httpClient.get('/financial/balance'))

  return {
    dataBalance: data,
    errorBalance: error,
    loadingBalance: loading,
    fetchBalance: makeRequest,
  }
}

export const useRequestWithdraw = () => {
  const { data, error, makeRequest, loading, statusCode } = useApi(params => {
    const { route, method } = getEndpoint('requestWithdraw')
    return httpClient[method](route, { ...params })
  })

  return {
    statusCodeRequestWithdraw: statusCode,
    dataRequestWithdraw: data,
    errorRequestWithdraw: error,
    loadingRequestWithdraw: loading,
    makeWithdraw: makeRequest,
  }
}

export const useFetchWithdrawals = () => {
  const { data, error, makeRequest, loading } = useApi(() => {
    const { route, method } = getEndpoint('fetchtWithdrawals')
    return httpClient[method](route)
  })

  return {
    dataWithdrawals: data,
    errorWithdrawals: error,
    loadingWithdrawals: loading,
    makeWithdrawals: makeRequest,
  }
}
