import { Badge } from '@/components/Common'
import { getStatusWithdrawal, getWithdrawalBadgeColor } from '../constants'

export const WithdrawalBadge = ({ status, className }) => (
  <Badge
    variant='solid'
    rounded='10px'
    className={`px-2 py-1 bg-${getWithdrawalBadgeColor(status)} ${className}`}
    text={getStatusWithdrawal(status)}
  />
)
