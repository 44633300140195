import { DateAsText } from '@/components/Common'
import { Copy } from '@/components/Common/Copy'
import { GridFragment } from '@/components/Common/GridFragment'
import { Loading } from '@/components/Common/Loading'
import { Button } from '@/components/Form'
import { formatNumberToBrDecimal, formatPhoneString } from '@/utils'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { APPROVED, getTypePix, PENDING, PHONE, REFUSED } from '../constants'
import { useApproveWithdrawAdmin, useGetWithdrawDetailAdmin } from '../hooks/useApi'
import { ModalRefuseWithdrawal } from './ModalRefuseWithdrawal'
import { WithdrawalBadge } from './WithdrawalBadge'

export const ModalWithdrawalDetails = ({ id, isOpen, onClose, onOpen, getWithdrawals }) => {
  const { getWithdrawalDetail, withdrawalDetailData, withdrawalDetailLoading } = useGetWithdrawDetailAdmin()
  const { isOpen: isOpenRefuseModal, onClose: onCloseRefuseModal, onOpen: onOpenRefuseModal } = useDisclosure()

  const { postWithdrawalApprove, withdrawalApproveLoading } = useApproveWithdrawAdmin()

  const handleApproveWithdrawl = () => {
    onClose()
    Swal.fire({
      title: 'Aprovar saque?',
      html: `
        <p>Você está prestes a aprovar este saque.</p>
        <span style="color: red;font-size: 14px;font-weight: 500">Esta ação não poderá ser desfeita!</span>
      `,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#66BB6A',
      cancelButtonColor: '#186885',
      reverseButtons: true,
    }).then(async result => {
      onOpen()
      if (result.isConfirmed)
        return postWithdrawalApprove(id)
          .then(() => {
            getWithdrawals()
            getWithdrawalDetail(id)
            handleUseToast('success', 'Saque aprovado com sucesso!', true)
            onClose()
          })
          .catch(() => {
            handleUseToast('error', 'Erro ao aprovar o saque!')
            onOpen()
          })
      onOpen()
    })
  }

  const handleUseToast = (status, text, close) => {
    toast[status](text, {
      position: 'top-center',
      autoClose: 2000,
    })
    if (close) onClose()
  }

  useEffect(() => {
    if (!id) return
    getWithdrawalDetail(id).catch(() => handleUseToast('error', 'Ocorreu um erro ao buscar os detalhes da promoção.', true))
  }, [id])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='2xl' isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div className='flex items-center justify-between'>
              <h2 className='text-2xl'>Detalhes</h2>
              {!withdrawalDetailLoading && withdrawalDetailData && (
                <WithdrawalBadge status={withdrawalDetailData.status} className='mr-8' />
              )}
            </div>
            <ModalCloseButton top={4} />
          </ModalHeader>
          <ModalBody className='flex flex-col'>
            {withdrawalDetailLoading && (
              <div className='flex my-10'>
                <Loading />
              </div>
            )}

            {!withdrawalDetailLoading && withdrawalDetailData && (
              <div className='flex flex-col gap-5'>
                <div className={`grid grid-cols-2 ${withdrawalDetailData.status !== REFUSED && 'mb-4'}`}>
                  <GridFragment title='Valor' body={formatNumberToBrDecimal(withdrawalDetailData.amount / 100)} />
                  <GridFragment
                    title={`Chave PIX (${getTypePix(Number(withdrawalDetailData.pixKeyType))})`}
                    body={
                      <Copy
                        value={
                          Number(withdrawalDetailData.pixKeyType) === PHONE
                            ? formatPhoneString(withdrawalDetailData.pixKey)
                            : withdrawalDetailData.pixKey
                        }
                        className='cursor-pointer flex items-center gap-1 hover:underline'
                      >
                        {Number(withdrawalDetailData.pixKeyType) === PHONE
                          ? formatPhoneString(withdrawalDetailData.pixKey)
                          : withdrawalDetailData.pixKey}
                        <Icon icon='mdi:content-copy' />
                      </Copy>
                    }
                  />

                  <GridFragment title='Nome do Titular' body={withdrawalDetailData.recipientName} />
                  <GridFragment title='Documento do Titular' body={withdrawalDetailData.recipientCpfCnpj} />
                  <GridFragment title='Nome do Solicitante' body={withdrawalDetailData.userName} />
                  <GridFragment title='E-mail do Solicitante' body={withdrawalDetailData.userEmail} />

                  <GridFragment
                    title='Data de Solicitação'
                    body={
                      withdrawalDetailData.createdAt ? <DateAsText type='datetime' date={withdrawalDetailData.createdAt} /> : '-'
                    }
                    className={withdrawalDetailData.status === PENDING ? 'col-span-2' : ''}
                  />
                  {withdrawalDetailData.status === REFUSED && (
                    <GridFragment
                      title='Data de Recusa'
                      body={
                        withdrawalDetailData.updatedAt ? (
                          <DateAsText type='datetime' date={withdrawalDetailData.updatedAt} />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {withdrawalDetailData.status === APPROVED && (
                    <GridFragment
                      title='Data de Aprovação'
                      body={
                        withdrawalDetailData.updatedAt ? (
                          <DateAsText type='datetime' date={withdrawalDetailData.updatedAt} />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {withdrawalDetailData.status === REFUSED && (
                    <div className='my-4'>
                      <h4 className='font-semibold text-lg'>Motivo da Recusa:</h4>
                      <p>{withdrawalDetailData.rejectionReason}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </ModalBody>
          {withdrawalDetailData && !withdrawalDetailLoading && withdrawalDetailData.status === PENDING && (
            <ModalFooter>
              <Button
                isLoading={withdrawalApproveLoading}
                isDisabled={withdrawalApproveLoading}
                onClick={() => {
                  onClose()
                  onOpenRefuseModal()
                }}
                className='mr-3 bg-negative'
              >
                Recusar
              </Button>
              <Button isLoading={withdrawalApproveLoading} isDisabled={withdrawalApproveLoading} onClick={handleApproveWithdrawl}>
                Aprovar
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
      <ModalRefuseWithdrawal
        getWithdrawals={getWithdrawals}
        getWithdrawalDetail={getWithdrawalDetail}
        id={id}
        isOpen={isOpenRefuseModal}
        onClose={onCloseRefuseModal}
        onOpenRefuseModal={onOpenRefuseModal}
        onCloseMainModal={onClose}
        onOpenMainModal={onOpen}
      />
    </>
  )
}
