import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { WithdrawalBadge } from './WithdrawalBadge'

export const WithdrawHistory = ({ dataWithdrawals }) => {
  return (
    <>
      <TableContainer className='max-lg:!hidden bg-surface shadow-xl rounded mt-4'>
        <Table size='md'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Status</Th>
              <Th textAlign='center'>Recebedor</Th>
              <Th textAlign='center'>Data de solicitação</Th>
              <Th textAlign='center'>Data de atualização</Th>
              <Th textAlign='center'>Valor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataWithdrawals?.map(item => (
              <Tr key={item.id}>
                <Td textAlign='center'>
                  <div className='flex items-center justify-center gap-2'>
                    <WithdrawalBadge status={item.status} />
                    {item.status === 3 && (
                      <Tooltip label={item.rejectionReason} placement='top'>
                        <Icon className='size-6 text-negative' icon='material-symbols-light:info-outline' />
                      </Tooltip>
                    )}
                  </div>
                </Td>

                <Td textAlign='center'>
                  <p className='truncate max-w-60 md:max-w-20 lg:max-w-[280px] xl:max-w-[380px]'>{item.recipientName}</p>
                </Td>

                <Td textAlign='center'>
                  <DateAsText date={item.createdAt} />
                </Td>

                <Td textAlign='center'>{item.updatedAt ? <DateAsText date={item.updatedAt} /> : <span>-</span>}</Td>

                <Td textAlign='end'>
                  <MoneyAsText value={item.amount / 100} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <div className='lg:hidden flex flex-col gap-4'>
        {dataWithdrawals?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Status</span>
              <div className='flex items-center gap-1'>
                <WithdrawalBadge status={item.status} />
              </div>
            </div>

            {item.status === 3 && (
              <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
                <span className='font-medium'>Razão</span>
                <p className='text-end truncate'>{item.rejectionReason}</p>
              </div>
            )}

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Recebedor</span>
              <p className='text-end truncate'>{item.recipientName}</p>
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Montante Sacado</span>
              <MoneyAsText value={item.amount / 100} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de solicitação</span>
              <DateAsText date={item.createdAt} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de atualização</span>
              {item.updatedAt ? <DateAsText date={item.updatedAt} /> : <span>-</span>}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
