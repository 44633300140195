import { useFetchPartner, useSubmitDonation } from '@/pages/Donation/hook'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useVerifyUser } from '@/pages/Donation/hook/useVerifyUser'
import { usePartnerCheckout } from '@/pages/Donation/hook/usePartnerCheckout'
import { useFormPayment } from '@/pages/Donation/hook/useFormPayment'
import { useFormCheckout } from '@/pages/Donation/hook/useFormCheckout'
import { useDisclosure } from '@chakra-ui/react'
import Swal from 'sweetalert2'
import { usePostCreatePayment } from '@/pages/Donation/hook/usePostCreatePayment'
import { toast } from 'react-toastify'
import { activeMethod } from '@/pages/Donation/constants'

const DonationContext = createContext({
  dataPartner: null,
  loadingPartner: null,
  checkout: null,
  setIsCheckout: null,
  donationAmount: null,
  setDonationAmount: null,
  isFavorite: null,
  setIsFavorite: null,
  loadingCheckout: null,
  isChecked: null,
  partnersForm: null,
  paymentForm: null,
  formCheckout: null,
  modalPartner: null,
  dataCreateDonation: null,
})

export const DonationProvider = ({ children }) => {
  const [checkout, setIsCheckout] = useState({
    isCheckout: false,
    infos: null,
  })
  const [isDonationAppwins, setIsDonationAppwins] = useState(false)
  const [donationAmount, setDonationAmount] = useState(1)
  const [isFavorite, setIsFavorite] = useState(false)
  const [payment, setPayment] = useState({
    id: null,
    payload: null,
    method: null,
  })

  const modalPartner = useDisclosure()
  const { dataPartner, fetchPartner, loadingPartner } = useFetchPartner()
  const { postCreatePayment, dataCreatePayment } = usePostCreatePayment()
  const { postDonation } = useSubmitDonation()

  const [isChecked, setIsChecked] = useState(false)
  const { indication_code, donor_indication } = useParams()

  const { requestVerify, loadingVerify } = useVerifyUser()

  const onSubmitDonation = ({ DonorValues, partnerValues }) => {
    const isPixStatic = dataPartner.settings.activePaymentMethod === activeMethod.PIX_STATIC
    const payload = {
      indicatedBy: donor_indication || dataPartner.linkCode || '',
      partnerId: dataPartner.id,
      amount: dataPartner.settings.donationPrice,
      quantity: donationAmount,
      isFavorite: isFavorite ? '1' : '0',
      becomePartner: 0,
    }
    const generatePaymentPayload = {
      quantity: donationAmount,
    }

    const gatewayPixPayload = { ...payload }

    if (partnerValues) {
      gatewayPixPayload.partner = {}
      for (const key in partnerValues) {
        if (typeof partnerValues[key] === 'object') {
          gatewayPixPayload.partner[key] = []
          for (let i = 0; i < partnerValues[key].length; i++) {
            payload[`partner[${key}][${i}]`] = partnerValues[key][i].value
            gatewayPixPayload.partner[key][i] = partnerValues[key][i].value
          }
          continue
        }
        payload[`partner[${key}]`] = partnerValues[key]
        gatewayPixPayload.partner[key] = partnerValues[key]
      }
      generatePaymentPayload['partner[name]'] = partnerValues.name
      generatePaymentPayload['partner[cpf]'] = partnerValues.cpf.replace(/\D/g, '')
      payload.quantity = 1
      gatewayPixPayload.quantity = 1
      payload.becomePartner = 1
      gatewayPixPayload.becomePartner = 1
      generatePaymentPayload['donor[]'] = ''
      gatewayPixPayload.donor = {
        name: partnerValues.name,
        cpf: partnerValues.cpf,
        email: partnerValues.email,
        phone: partnerValues.phone,
      }
      payload['donor[name]'] = partnerValues.name
      payload['donor[cpf]'] = partnerValues.cpf
      payload['donor[email]'] = partnerValues.email
      payload['donor[phone]'] = partnerValues.phone
    } else {
      payload[`partner[]`] = ''
      gatewayPixPayload.partner = {}
      generatePaymentPayload['partner[]'] = 'null'
      generatePaymentPayload['donor[name]'] = DonorValues.name
      generatePaymentPayload['donor[cpf]'] = DonorValues.cpf.replace(/\D/g, '')

      payload['donor[name]'] = DonorValues.name
      payload['donor[email]'] = DonorValues.email
      payload['donor[phone]'] = DonorValues.phone
      payload['donor[cpf]'] = DonorValues.cpf
      payload['donor[id]'] = DonorValues.donorId || ''
      gatewayPixPayload.donor = {
        name: DonorValues.name,
        email: DonorValues.email,
        phone: DonorValues.phone,
        cpf: DonorValues.cpf,
        id: DonorValues.donorId || '',
      }
    }

    Swal.fire({
      title: 'Confirmar Envio?',
      html: `
      <div>
        <p>Você está prestes a enviar sua doação para etapa de pagamento.</p>
        <small class='text-red-500 font-medium'>Essa ação não poderá ser desfeita!</small>
      </div>
    `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let donationId = null

        if (!isPixStatic) {
          payload.paymentProofFile = ''
          gatewayPixPayload.paymentProofFile = null

          const payloadData = Object.entries(payload)
          const formData = new FormData()
          payloadData.forEach(item => {
            formData.append(item[0], item[1])
          })

          await postDonation(gatewayPixPayload)
            .then(res => {
              toast.success('Pagamento gerado com sucesso!')
              donationId = res.data.data.id
            })
            .catch(error => {
              if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'phone') {
                toast.error('Número de telefone já está em uso!')
                return
              }

              if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'email') {
                toast.error('Email já está em uso!')
                return
              }

              if (error.response?.data.data.error === 'fieldAlreadyInUse' && error.response?.data.data.field === 'cpfCnpj') {
                toast.error('Documento já está em uso!')
                return
              }

              if (error.response?.data.data.error === 'invalidCpf') {
                toast.error('Cpf inválido!')
                if (partnerValues) modalPartner.onOpen()
                return
              }

              toast.error('Ocorreu um erro ao gerar seu pedido!')
              return
            })

          if (donationId) {
            generatePaymentPayload.donationId = donationId

            await postCreatePayment(generatePaymentPayload)
              .then(() => {
                setPayment({ id: donationId, payload, method: isPixStatic })
                setIsCheckout({ isCheckout: true, infos: isChecked ? partnerValues : DonorValues })
              })
              .catch(error => {
                if (error.data?.data?.error === 'phoneAlreadyInUse') {
                  toast.error('Número de telefone já está em uso!')
                  return
                }

                if (error.response?.data.data.error === 'invalidCpf') {
                  toast.error('Cpf inválido!')
                  if (partnerValues) modalPartner.onOpen()
                  return
                }

                toast.error('Ocorreu um erro ao enviar seu pedido!')
                return
              })
          }

          return
        }

        generatePaymentPayload.donationId = ''

        await postCreatePayment(generatePaymentPayload)
          .then(() => {
            setPayment({ id: null, payload, method: isPixStatic })
            setIsCheckout({ isCheckout: true, infos: isChecked ? partnerValues : DonorValues })
          })
          .catch(error => {
            if (error.data?.data?.error === 'phoneAlreadyInUse') {
              toast.error('Número de telefone já está em uso!')
              return
            }

            if (error.response?.data.data.error === 'invalidCpf') {
              toast.error('Cpf inválido!')
              if (partnerValues) modalPartner.onOpen()
              return
            }

            toast.error('Ocorreu um erro ao enviar seu pedido!')
            return
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (!result.isConfirmed) {
        isChecked && modalPartner.onOpen()
      }
    })
  }

  const partnerCheckout = usePartnerCheckout({ setIsCheckout, onClosePartnerModal: modalPartner.onClose, onSubmitDonation })
  const { partnersForm, getMultiOptions } = partnerCheckout

  const paymentForm = useFormPayment({ payment, indication_code })

  const formCheckout = useFormCheckout({
    isChecked,
    requestVerify,
    setIsCheckout,
    onOpenPartnerModal: modalPartner.onOpen,
    checkout,
    dataPartner,
    donationAmount,
    isFavorite,
    partnersForm,
    onSubmitDonation,
  })

  useEffect(() => {
    if (!formCheckout.formParams.values.email) return
    partnersForm.setFieldValue('email', formCheckout.formParams.values.email)
  }, [formCheckout.formParams.values.email])

  useEffect(() => {
    if (!dataPartner) return
    const isWins = indication_code.toUpperCase() === dataPartner.settings.networkHeadIndicationCode
    setIsDonationAppwins(isWins)
    setIsChecked(isWins)
  }, [dataPartner])

  useEffect(() => {
    fetchPartner(indication_code)
    getMultiOptions()
  }, [])

  return (
    <DonationContext.Provider
      value={{
        loadingCheckout: loadingVerify,
        isChecked,
        setIsChecked,
        dataPartner,
        loadingPartner,
        checkout,
        setIsCheckout,
        donationAmount,
        setDonationAmount,
        isFavorite,
        setIsFavorite,
        paymentForm,
        formCheckout,
        partnerCheckout,
        modalPartner,
        isDonationAppwins,
        dataCreateDonation: dataCreatePayment,
      }}
    >
      {children}
    </DonationContext.Provider>
  )
}

export const useDonation = () => {
  return { ...useContext(DonationContext) }
}
