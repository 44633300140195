import { Button, CheckBox } from '@/components/Form'
import { CpfCnpjField, PhoneField, TextField } from '@/components/Form/Fields'
import { useDonation } from '@/contexts/DonationContext'
import { formatCpfCnpj, formatPhoneString } from '@/utils'
import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { inputStyles } from '../constants/styles'

export const CheckoutForm = () => {
  const [isMoble, setIsMoble] = useState()

  const { checkout, partnerCheckout, formCheckout, loadingCheckout, isChecked, setIsChecked } = useDonation()
  const { isCheckout, infos } = checkout
  const { values, setFieldValue, handleSubmit, errors, handleChange } = formCheckout.formParams
  const { handleClearVerify } = formCheckout

  const CheckoutCell = ({ label, value }) => (
    <div className='w-full pb-1 max-2xl:border-b border-slate-300 flex items-center gap-1 flex-wrap'>
      <span className='text-sm font-medium'>{label}</span>
      <span className='max-w-lg truncate'>{value}</span>
    </div>
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMoble(window.innerWidth < 768)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const infoText = isMoble
    ? 'O e-mail será vinculado ao cadastro atual e não poderá ser usado em outro.'
    : 'O endereço de e-mail será associado ao cadastro atual e não poderá ser reutilizado em outro tipo de cadastro.'

  return (
    <div className='w-full max-lg:max-w-lg max-lg:mx-auto flex flex-col gap-1 p-4 py-6 bg-surface rounded-lg shadow-xl'>
      <div className='w-full flex justify-between items-end border-b border-primary-blue/50 pb-2 mb-3'>
        <h4 className='text-lg font-medium'>Checkout</h4>

        {isCheckout ? (
          <Icon icon='ic:round-check-box' className='text-2xl text-positive' />
        ) : (
          <Icon icon='ic:round-warning' className='text-2xl text-yellow-600' />
        )}
      </div>

      {isCheckout && (
        <>
          {partnerCheckout.partnersForm.values.cep ? (
            <div className='flex max-2xl:flex-col gap-4 animate-fadeIn'>
              <CheckoutCell label='Nome Completo:' value={partnerCheckout.partnersForm.values.name} />

              <CheckoutCell label='E-mail:' value={partnerCheckout.partnersForm.values.email} />

              <CheckoutCell label='Telefone:' value={formatPhoneString(partnerCheckout.partnersForm.values.phone)} />

              <CheckoutCell label='CPF:' value={formatPhoneString(formatCpfCnpj.partnersForm.values.cpf)} />
            </div>
          ) : (
            <div className='flex max-2xl:flex-col gap-4 animate-fadeIn'>
              <CheckoutCell label='Nome Completo:' value={infos.name} />

              <CheckoutCell label='E-mail:' value={infos.email} />

              <CheckoutCell label='Telefone:' value={formatPhoneString(infos.phone)} />

              <CheckoutCell label='CPF:' value={formatCpfCnpj(infos.cpf)} />
            </div>
          )}
        </>
      )}

      {!isCheckout && (
        <form className='flex flex-col gap-4' onSubmit={handleSubmit}>
          <TextField
            name='email'
            value={values.email}
            onChange={e => {
              setFieldValue('email', e.target.value.trim())
            }}
            errorMessage={errors.email}
            label='E-mail'
            inputClassName={inputStyles}
            noMargins
            placeholder='Ex.: joao@mail.com'
            isDisabled={values.isVerified}
            info={infoText}
          />
          {!values.isVerified && (
            <CheckBox label='Tornar-se Parceiro' name='isChecked' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          )}

          {values.isVerified && (
            <>
              <CpfCnpjField
                name='cpf'
                value={values.cpf}
                onChange={handleChange}
                errorMessage={errors.cpf}
                label='CPF'
                inputClassName={inputStyles}
                placeholder='Ex.: 349.251.155-45'
                isDisabled={values.isFilled}
                noMargins
              />

              <TextField
                value={values.name}
                onChange={e => setFieldValue('name', e.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\sçÇ]/g, ''))}
                errorMessage={errors.name}
                name='name'
                label='Nome Completo'
                inputClassName={inputStyles}
                placeholder='Ex.: João da Silva'
                maxLength={90}
                isDisabled={values.isFilled}
                noMargins
              />

              <PhoneField
                name='phone'
                value={values.phone}
                onChange={v => setFieldValue('phone', v.value)}
                errorMessage={errors.phone}
                label='Telefone'
                inputClassName={inputStyles}
                placeholder='(00) 00000-0000'
                isDisabled={values.isFilled}
                noMargins
              />
            </>
          )}

          <div className='flex justify-center gap-2 max-[520px]:flex-col'>
            {values.isVerified && (
              <Button
                type='button'
                onClick={handleClearVerify}
                className='w-full bg-transparent ring ring-inset rounded ring-slate-700 text-black'
              >
                Alterar e-mail
              </Button>
            )}

            <Button
              type='submit'
              isLoading={loadingCheckout}
              isDisabled={loadingCheckout}
              className='w-full rounded text-center flex justify-center'
            >
              Continuar
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
