import { EmptyList } from '@/components/Common/EmptyList'
import { LoadingData } from '@/components/Common/LoadingData'
import { Pagination } from '@/components/Common/Pagination'
import { Button } from '@/components/Form'
import { useAuth } from '@/contexts'
import { useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { ModalNewPromo } from './components/ModalNewPromo'
import { ModalPromoDetails } from './components/ModalPromoDetails'
import { PromoFilter } from './components/PromoFilter'
import { PromoTable } from './components/PromoTable'
import { useGetPromos } from './hooks/useApi'

export const Promotions = () => {
  const [filtersQueries, setFiltersQueries] = useState({})
  const [promoId, setPromoId] = useState(null)
  const { getPromos, promoLoading, promoMeta, promoData } = useGetPromos()
  const { isOpen: isOpenNewPromo, onOpen: onOpenNewPromo, onClose: onCloseNewPromo } = useDisclosure()
  const { isOpen: isOpenPromoDetails, onOpen: onOpenPromoDetails, onClose: onClosePromoDetails } = useDisclosure()
  const { userGroup, user } = useAuth()

  const onPageChange = params => {
    getPromos({ ...filtersQueries, ...params })
  }

  const handleOpenModalDetails = id => {
    setPromoId(id)
    onOpenPromoDetails()
  }

  return (
    <section className='flex flex-col justify-center gap-3 px-4 mb-6'>
      <div className='flex justify-between items-baseline'>
        <h2 className='text-2xl font-semibold text-primary'>Promoções</h2>
        {!userGroup.isAdmin && (
          <Button isDisabled={user?.disabledCreatePromo || false} onClick={onOpenNewPromo}>
            Novo
          </Button>
        )}
      </div>

      <ModalNewPromo isOpen={isOpenNewPromo} onClose={onCloseNewPromo} onOpen={onOpenNewPromo} />
      <ModalPromoDetails
        getPromos={getPromos}
        isOpen={isOpenPromoDetails}
        onClose={onClosePromoDetails}
        id={promoId}
        onOpen={onOpenPromoDetails}
      />

      <PromoFilter getPromos={getPromos} promoLoading={promoLoading} setFiltersQueries={setFiltersQueries} />

      {promoLoading ? <LoadingData /> : <PromoTable promoData={promoData} onOpenPromoDetails={handleOpenModalDetails} />}

      {promoData?.length === 0 && !promoLoading && <EmptyList message='Nenhum resultado encontrado!' />}

      {promoMeta && <Pagination pagination={promoMeta} onPageChange={onPageChange} />}
    </section>
  )
}
