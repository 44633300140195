export const ContainerSection = ({ title, children }) => {
  return (
    <>
      <section className='flex flex-col justify-center gap-3'>
        <h2 className='text-xl font-semibold text-primary'>{title}</h2>
        {children}
      </section>
    </>
  )
}
