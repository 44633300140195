import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CNPJ, CPF, EMAIL, PENDING, PHONE, RANDOM_KEY } from '../constants'

export const useFilterWithdrawalForm = ({ onSubmit }) => {
  const schema = Yup.object({
    status: Yup.string(),
    userName: Yup.string(),
    initialDate: Yup.string(),
    endDate: Yup.string().when('initialDate', {
      is: value => (!value ? false : value.length > 1),
      then: () => Yup.string().required('Campo obrigatório!'),
      otherwise: Yup.string().notRequired(),
    }),
  })

  return useFormik({
    initialValues: {
      initialDate: '',
      endDate: '',
      status: PENDING,
      userName: '',
    },
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}

export const useForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const Schema = Yup.object({
    amount: Yup.number().required(t('global.validation.required')),
    recipientName: Yup.string().required(t('global.validation.required')),
    pixKeyType: Yup.string().required(t('global.validation.required')),
    pixKey: Yup.string()
      .when('pixKeyType', {
        is: value => value === EMAIL.toString(),
        then: Yup.string().email(t('global.validation.invalidEmail')).required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKeyType', {
        is: value => value === CPF.toString(),
        then: Yup.string()
          .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKeyType', {
        is: value => value === CNPJ.toString(),
        then: Yup.string()
          .test('lengthMinCnpj', t('global.validation.cnpjLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 14 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKeyType', {
        is: value => value === PHONE.toString(),
        then: Yup.string()
          .test('lengthMinPhone', t('global.validation.invalidPhone'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('pixKeyType', {
        is: value => value === RANDOM_KEY.toString(),
        then: Yup.string().required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      }),
    recipientDocument: Yup.string().required(t('global.validation.required')),
    recipientCpfCnpj: Yup.string()
      .when('recipientDocument', {
        is: value => value === CPF.toString(),
        then: Yup.string()
          .test('lengthMinCpf', t('global.validation.cpfLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      })
      .when('recipientDocument', {
        is: value => value === CNPJ.toString(),
        then: Yup.string()
          .test('lengthMinCnpj', t('global.validation.cnpjLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 14 || clearValue.length === 0
          })
          .required(t('global.validation.required')),
        otherwise: Yup.string().required(t('global.validation.required')),
      }),
  })

  return useFormik({
    initialValues: {
      amount: '',
      recipientName: '',
      pixKeyType: '',
      pixKey: '',
      recipientDocument: '',
      recipientCpfCnpj: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
