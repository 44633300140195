// Withdrawal Status
export const PENDING = 1
export const APPROVED = 2
export const REFUSED = 3

//PIX Type
export const CPF = 1
export const CNPJ = 2
export const EMAIL = 3
export const PHONE = 4
export const RANDOM_KEY = 5

export const getStatusWithdrawal = status => {
  switch (status) {
    case PENDING:
      return 'Pendente'
    case APPROVED:
      return 'Aprovado'
    case REFUSED:
      return 'Recusado'
    default:
      return 'Desconhecido'
  }
}

export const getStatusOptionsWithdrawal = () => {
  return [
    { value: PENDING, label: getStatusWithdrawal(PENDING) },
    { value: APPROVED, label: getStatusWithdrawal(APPROVED) },
    { value: REFUSED, label: getStatusWithdrawal(REFUSED) },
  ]
}

export const getWithdrawalBadgeColor = status => {
  switch (status) {
    case PENDING:
      return 'yellow-500'
    case APPROVED:
      return 'positive-light'
    case REFUSED:
      return 'negative-light'
  }
}

export const getTypePix = type => {
  switch (type) {
    case CPF:
      return 'CPF'
    case CNPJ:
      return 'CNPJ'
    case EMAIL:
      return 'E-mail'
    case PHONE:
      return 'Telefone'
    case RANDOM_KEY:
      return 'Chave aleatória'
  }
}

export const getTypePixOptions = () => {
  return [
    { label: getTypePix(CPF), value: CPF },
    { label: getTypePix(CNPJ), value: CNPJ },
    { label: getTypePix(EMAIL), value: EMAIL },
    { label: getTypePix(PHONE), value: PHONE },
    { label: getTypePix(RANDOM_KEY), value: RANDOM_KEY },
  ]
}
export const getDocumentTypeOptions = () => {
  return [
    { label: getTypePix(CPF), value: CPF },
    { label: getTypePix(CNPJ), value: CNPJ },
  ]
}
