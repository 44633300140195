import { Button } from '@/components/Form'
import { MoneyField, SelectField, TextField } from '@/components/Form/Fields'
import { toast } from 'react-toastify'
import { getDocumentTypeOptions, getTypePixOptions } from '../constants'
import { useForm, useRequestWithdraw, verifyPixKeyType, verifyRecipeintDoc } from '../hooks'

export const WithdrawForm = ({ minWithdrawValue, fetchBalance, makeWithdrawals }) => {
  const { makeWithdraw, loadingRequestWithdraw } = useRequestWithdraw()

  const formatCurrency = value => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
  }

  const formValidation = useForm({
    onSubmit: values => {
      if (values.amount < minWithdrawValue) {
        const minWithdrawValueText = formatCurrency(minWithdrawValue)

        toast.error(`O valor mínimo de saque é de ${minWithdrawValueText}`)
        return
      }

      makeWithdraw({
        recipientName: values.recipientName,
        recipientCpfCnpj: values.recipientCpfCnpj,
        pixKey: values.pixKey,
        amount: values.amount * 100,
        pixKeyType: Number(values.pixKeyType),
      })
        .then(() => {
          fetchBalance()
          makeWithdrawals()
          toast.success('Solicitação de saque realizada com sucesso!')
          return
        })
        .catch(error => {
          if (error.response.data.data.error === 'insufficientBalance') {
            toast.error('Saldo insuficiente!')
            return
          }
          if (error.response.data.data.error === 'userAlreadyHasAPendingRequest') {
            toast.error('Saque pendente. Aguarde o processamento para fazer uma nova solicitação!')
            return
          }
          toast.error('Erro nesta solicitação. Tente novamente mais tarde!')
          return
        })
    },
  })

  return (
    <form onSubmit={formValidation.handleSubmit} className='flex flex-col w-full gap-3'>
      <div className='flex flex-col md:flex-row items-center gap-3'>
        <MoneyField
          name='amount'
          isDisabled={loadingRequestWithdraw}
          values={formValidation.values.amount}
          onChange={e => formValidation.setFieldValue('amount', e.numberValue)}
          label='Valor'
          className='w-full'
          noMargins
          placeholder='Ex.: R$ 100,00'
          isInvalid={formValidation.errors.amount}
          errorMessage={formValidation.errors.amount}
        />
        <SelectField
          name='pixKeyType'
          isDisabled={loadingRequestWithdraw}
          values={formValidation.values.pixKeyType}
          onChange={e => {
            formValidation.handleChange(e)
            formValidation.setFieldValue('pixKey', '')
          }}
          errorMessage={formValidation.errors.pixKeyType}
          label='Tipo da Chave'
          className='w-full'
          noMargins
          placeholder='Ex.: CPF'
          options={getTypePixOptions()}
        />
        {verifyPixKeyType(formValidation)}
      </div>
      <div className='flex flex-col lg:flex-row gap-3'>
        <TextField
          name='recipientName'
          isDisabled={loadingRequestWithdraw}
          values={formValidation.values.recipientName}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.recipientName}
          label='Titular da Conta'
          className='w-full'
          noMargins
          placeholder='Ex.: João'
        />
        <SelectField
          name='recipientDocument'
          isDisabled={loadingRequestWithdraw}
          values={formValidation.values.recipientDocument}
          onChange={e => {
            formValidation.handleChange(e)
            formValidation.setFieldValue('recipientCpfCnpj', '')
          }}
          errorMessage={formValidation.errors.recipientDocument}
          label='Tipo do Documento'
          className='w-full'
          noMargins
          placeholder='Ex.: CPF'
          options={getDocumentTypeOptions()}
        />
        {verifyRecipeintDoc(formValidation)}
      </div>
      <Button
        isLoading={loadingRequestWithdraw}
        isDisabled={loadingRequestWithdraw}
        type='submit'
        text='Sacar'
        className='w-full py-3'
      />
    </form>
  )
}
