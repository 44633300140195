import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Button } from '@/components/Form'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import { WithdrawalBadge } from './WithdrawalBadge'

export const WithdrawalTable = ({ withdrawalData, handleOpenModalDetails }) => {
  return (
    <>
      <TableContainer className='max-lg:!hidden bg-surface shadow-xl rounded mt-4'>
        <Table size='md'>
          <Thead>
            <Tr>
              <Th>Solicitante</Th>
              <Th textAlign='center'>Data de solicitação</Th>
              <Th textAlign='center'>Status</Th>
              <Th textAlign='end'>Valor</Th>
              <Th textAlign='center'>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdrawalData?.map(item => (
              <Tr key={item.id}>
                <Td>
                  <p className='truncate max-w-60 md:max-w-20 lg:max-w-[200px] xl:max-w-[360px]'>{item.userName}</p>
                </Td>

                <Td textAlign='center'>
                  <DateAsText date={item.createdAt} type='datetime' />
                </Td>

                <Td textAlign='center'>
                  <WithdrawalBadge status={item.status} />
                </Td>

                <Td textAlign='end'>
                  <MoneyAsText value={item.amount / 100} />
                </Td>

                <Td className='max-w-[360px]'>
                  <div className='w-full flex justify-center items-center gap-2'>
                    <Tooltip label='Visualizar'>
                      <div onClick={() => handleOpenModalDetails(item.id)}>
                        <Button className='p-2 bg-primary-800/10 text-text border-primary-600 rounded-lg gap-1'>
                          <Icon className='text-lg' icon='mdi:eye-outline' />
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <div className='lg:hidden flex flex-col gap-4'>
        {withdrawalData?.map(item => (
          <div key={item.id} className='flex flex-col bg-surface rounded-lg w-full my-2 animate-fadeIn gap-2 text-text shadow-lg'>
            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Solicitante</span>
              <p className='text-end truncate'>{item.userName}</p>
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Data de solicitação</span>
              <DateAsText type='datetime' date={item.createdAt} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Status</span>
              <WithdrawalBadge status={item.status} />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-3 w-full p-4 border-b border-primary-400/50'>
              <span className='font-medium'>Valor</span>
              <MoneyAsText value={item.amount / 100} />
            </div>

            <div className='flex items-center justify-between gap-3 w-full p-4 border-primary-400/50'>
              <Button className='w-full' onClick={() => handleOpenModalDetails(item.id)}>
                Ver detalhes
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
