import { DateAsText } from '@/components/Common'
import { Button } from '@/components/Form'
import { DateField, SelectField, TextField } from '@/components/Form/Fields'
import { useAuth } from '@/contexts'
import { inputStyles } from '@/pages/Dashboard/constants/styles'
import { FilterBuilder } from '@/utils'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { PENDING, getStatusOptionsWithdrawal, getStatusWithdrawal } from '../constants'
import { useFilterWithdrawalForm } from '../hooks/useForm'

export const WithdrawalFilter = ({ getWithdrawals, withdrawalLoading, setFiltersQueries }) => {
  const [activeFilters, setActiveFilters] = useState([{ label: 'status', value: PENDING }])
  const { user } = useAuth()
  const { handleChange, values, errors, handleSubmit, resetForm, setFieldValue } = useFilterWithdrawalForm({
    onSubmit: values => {
      const filters = FilterBuilder()
        .between(
          'createdAt',
          values.initialDate.length > 0 ? `${values.initialDate}${values.endDate.length > 0 ? `,${values.endDate}` : ''}` : null
        )
        .like('user.name', values.userName)
        .equal('status', values.status ? values.status : null)
        .build()

      setFiltersQueries({ ...filters, page: 1 })
      const newFilters = Object.entries(values)
      setActiveFilters(newFilters.filter(i => i[1] !== '').map(i => ({ label: i[0], value: i[1] })))

      getWithdrawals(filters)
    },
  })

  const handleChangeInitialDate = e => {
    if (!e.target.value) {
      setFieldValue('endDate', e.target.value)
    }
    setFieldValue('initialDate', e.target.value)
  }

  useEffect(() => {
    if (!user) return
    const filters = FilterBuilder().equal('status', PENDING).build()
    getWithdrawals(filters)
  }, [user])

  const handleClearFilter = () => {
    resetForm()
    setActiveFilters([{ label: 'status', value: PENDING }])
    setFieldValue('status', PENDING)
    const filters = FilterBuilder().equal('status', PENDING).build()
    getWithdrawals(filters)
  }

  const renderTag = (label, value) => {
    switch (label) {
      case 'userName':
        return `Nome do solicitante: ${value}`
      case 'initialDate':
        return (
          <>
            Data inicial: <DateAsText date={value} />
          </>
        )
      case 'endDate':
        return (
          <>
            Data final: <DateAsText date={value} />
          </>
        )
      case 'status':
        return `Status: ${getStatusWithdrawal(Number(value))}`
    }
  }

  return (
    <Accordion allowToggle className='bg-surface shadow-lg rounded' padding={1} pt={2}>
      <AccordionItem border={0}>
        <AccordionButton>
          <div className='w-full flex flex-col items-start'>
            <div className='w-full flex justify-between gap-2'>
              <h3 className='text-lg font-medium text-left'>Filtros de busca</h3>
              <AccordionIcon fontSize={28} />
            </div>

            {activeFilters.length > 0 && (
              <div className='flex gap-2 justify-start items-center flex-wrap py-2'>
                {activeFilters.map((filter, ind) => (
                  <span className='bg-tertiary-600 text-zinc-50 p-1 px-3 rounded text-sm' key={ind}>
                    {renderTag(filter.label, filter.value)}
                  </span>
                ))}
              </div>
            )}
          </div>
        </AccordionButton>

        <AccordionPanel>
          <form onSubmit={handleSubmit} className={'flex flex-col md:grid grid-cols-4 gap-3 w-full'}>
            <TextField
              label='Nome do solicitante'
              noMargins
              className='w-full'
              inputClassName={`${inputStyles} h-[44px]`}
              value={values.userName}
              name='userName'
              onChange={handleChange}
            />

            <SelectField
              options={getStatusOptionsWithdrawal()}
              label='Status'
              noMargins
              className='w-full'
              optionPrimary='Todos'
              value={values.status}
              onChange={handleChange}
              name='status'
              inputClassName={`${inputStyles} h-[44px]`}
            />

            <DateField
              className='w-full'
              label='Data inicial'
              name='initialDate'
              value={values.initialDate}
              onChange={handleChangeInitialDate}
              errorMessage={errors.initialDate}
              inputClassName={inputStyles}
              noMargins
            />

            <DateField
              label='Data final'
              name='endDate'
              className='w-full'
              value={values.endDate}
              onChange={handleChange}
              errorMessage={errors.endDate}
              inputClassName={twMerge(inputStyles, values.initialDate.length === 0 && 'text-opacity-50')}
              isDisabled={values.initialDate.length === 0}
              noMargins
              min={values.initialDate}
            />
            <div className='col-span-2'></div>

            <Button
              type='button'
              onClick={handleClearFilter}
              className={twMerge('w-full p-2 self-end rounded xl:col-span-1 h-[44px]')}
              isDisabled={withdrawalLoading}
            >
              Limpar
            </Button>
            <Button
              type='submit'
              className={twMerge('w-full p-2 self-end rounded xl:col-span-1 h-[44px]')}
              isDisabled={withdrawalLoading}
            >
              Pesquisar
            </Button>
          </form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
