const endpoints = [
  { name: 'fetchDonationList', route: '/donationList', method: 'get' },
  { name: 'fetchDonationDetails', route: '/donationList/{id}', method: 'get' },
  { name: 'requestWithdraw', route: '/financial/withdrawal', method: 'post' },
  { name: 'fetchtWithdrawals', route: '/financial/my-withdrawals', method: 'get' },
  { name: 'fetchWithdrawalsAdmin', route: '/admin/withdrawals', method: 'get' },
  { name: 'fetchWithdrawalDetailAdmin', route: '/admin/withdrawals/{id}', method: 'get' },
  { name: 'approveWithdrawalAdmin', route: '/admin/withdrawals/{id}/approve', method: 'put' },
  { name: 'rejectWithdrawalAdmin', route: '/admin/withdrawals/{id}/reject', method: 'put' },
]

export const getEndpoint = (routeName, routeParams = {}) => {
  const endpointsCopy = JSON.parse(JSON.stringify(endpoints))
  const endpoint = endpointsCopy.find(row => row.name === routeName)
  if (!endpoint) throw new Error(`invalid endpoint name '${routeName}'.`)
  for (let paramName in routeParams) {
    endpoint.route = endpoint.route.replace(`{${paramName}}`, routeParams[paramName])
  }
  return endpoint
}
