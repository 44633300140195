import { Button } from '@/components/Form'
import { TextareaField } from '@/components/Form/Fields'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useRefuseWithdrawAdmin } from '../hooks/useApi'

export const ModalRefuseWithdrawal = ({
  id,
  isOpen,
  onClose,
  onOpenRefuseModal,
  onOpenMainModal,
  onCloseMainModal,
  getWithdrawals,
  getWithdrawalDetail,
}) => {
  const [textareaValue, setTextareaValue] = useState('')
  const [textareaError, setTextareaError] = useState('')

  const { postWithdrawalRefuse, withdrawalRefuseLoading } = useRefuseWithdrawAdmin()

  const handleRefuse = () => {
    if (!textareaValue) return setTextareaError('Campo obrigatório')
    setTextareaError('')
    onClose()
    Swal.fire({
      title: 'Recusar saque?',
      icon: 'warning',
      html: `
        <p>Você está prestes a recusar esse saque.</p>
        <span style="color: red;font-size: 14px;font-weight: 500">Esta ação não poderá ser desfeita!</span>
      `,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      confirmButtonColor: '#F44336',
      cancelButtonColor: '#186885',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) return refuseWithdrawal()
      onOpenRefuseModal()
    })
  }

  useEffect(() => {
    if (!id) return
    setTextareaValue('')
  }, [id])

  const handleCloseModal = () => {
    onClose()
    onOpenMainModal()
  }

  const refuseWithdrawal = () => {
    onOpenRefuseModal()
    postWithdrawalRefuse({ id, rejectionReason: textareaValue })
      .then(() => {
        getWithdrawals()
        getWithdrawalDetail(id)

        toast.success('Saque recusado com sucesso!', {
          position: 'top-center',
          autoClose: 2000,
        })

        onClose()
        onCloseMainModal()
      })
      .catch(() =>
        toast.error('Ocorreu um erro ao recusar o saque.', {
          position: 'top-center',
          autoClose: 2000,
        })
      )
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size='2xl' isCentered scrollBehavior='inside'>
      <ModalOverlay />

      <ModalContent zIndex={20}>
        <ModalHeader>
          <h2 className='text-2xl'>Motivo da recusa</h2>
          <ModalCloseButton style={{ top: '40px !important' }} />
        </ModalHeader>

        <ModalBody className='flex flex-col gap-4'>
          <TextareaField
            value={textareaValue}
            onChange={e => setTextareaValue(e.target.value)}
            errorMessage={textareaError}
            disabled={withdrawalRefuseLoading}
          />
        </ModalBody>

        <ModalFooter className='flex gap-3'>
          <Button onClick={handleCloseModal} type='button' className='bg-zinc-500 rounded'>
            Cancelar
          </Button>
          <Button
            type='submit'
            isDisabled={withdrawalRefuseLoading}
            isLoading={withdrawalRefuseLoading}
            className='rounded bg-primary'
            onClick={handleRefuse}
          >
            Recusar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
