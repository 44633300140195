import { EmptyList } from '@/components/Common/EmptyList'
import { LoadingData } from '@/components/Common/LoadingData'
import { Pagination } from '@/components/Common/Pagination'
import { useAuth } from '@/contexts'
import { FilterBuilder } from '@/utils'
import { useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BlockWithdraw, ContainerSection, ExtractCard, WithdrawForm, WithdrawHistory } from './components'
import { ModalWithdrawalDetails } from './components/ModalWithdrawalDetails'
import { WithdrawalFilter } from './components/WithdrawalFilter'
import { WithdrawalTable } from './components/WithdrawalTable'
import { PENDING } from './constants'
import { useFetchWithdrawals, useGetBalance } from './hooks'
import { useGetWithdrawsAdmin } from './hooks/useApi'

export const Withdrawal = () => {
  const [filtersQueries, setFiltersQueries] = useState(() => FilterBuilder().equal('status', PENDING).build())
  const [withdrawalId, setWithdrawalId] = useState()
  const { userGroup } = useAuth()
  const { isAdmin } = userGroup
  const { isOpen: isOpenWithdrawalDetails, onOpen: onOpenWithdrawalDetails, onClose: onCloseWithdrawalDetails } = useDisclosure()
  const { getWithdrawals, withdrawalData, withdrawalMeta, withdrawalLoading } = useGetWithdrawsAdmin()
  const { dataBalance, loadingBalance, fetchBalance } = useGetBalance()
  const { dataWithdrawals, makeWithdrawals, loadingWithdrawals } = useFetchWithdrawals()
  const minWithdrawValue = 5

  const onPageChange = params => {
    getWithdrawals({ ...filtersQueries, ...params })
  }

  const handleOpenModalDetails = id => {
    setWithdrawalId(id)
    onOpenWithdrawalDetails()
  }

  useEffect(() => {
    if (Object.values(userGroup).indexOf(true) === -1) return

    if (isAdmin) {
      getWithdrawals({ ...filtersQueries })
      return
    }

    fetchBalance()
    makeWithdrawals()
  }, [userGroup])

  if (isAdmin)
    return (
      <section className='flex flex-col justify-center gap-3 px-4 mb-6'>
        <h2 className='text-2xl font-semibold text-primary'>Solicitações de saque</h2>

        <ModalWithdrawalDetails
          getWithdrawals={getWithdrawals}
          isOpen={isOpenWithdrawalDetails}
          onClose={onCloseWithdrawalDetails}
          id={withdrawalId}
          onOpen={onOpenWithdrawalDetails}
        />

        <WithdrawalFilter
          getWithdrawals={getWithdrawals}
          withdrawalLoading={withdrawalLoading}
          setFiltersQueries={setFiltersQueries}
        />

        {withdrawalLoading ? (
          <LoadingData />
        ) : (
          <WithdrawalTable handleOpenModalDetails={handleOpenModalDetails} withdrawalData={withdrawalData} />
        )}

        {withdrawalData?.length === 0 && !withdrawalLoading && <EmptyList message='Nenhum resultado encontrado!' />}

        {withdrawalMeta && <Pagination pagination={withdrawalMeta.pagination} onPageChange={onPageChange} />}
      </section>
    )

  return (
    <main className='w-full max-w-4xl flex flex-col mx-auto px-4 pb-8 gap-4'>
      {dataBalance?.monetary < minWithdrawValue ? (
        <BlockWithdraw />
      ) : (
        <>
          <h1 className='text-2xl font-bold text-primary'>Saque</h1>
          <ContainerSection>
            <div className='grid md:grid-cols-2 gap-2 w-full items-stretch'>
              <div className='pr-1'>
                <ExtractCard title='Saldo' value={dataBalance?.monetary || 0} isLoading={loadingBalance} />
              </div>
              <div></div>
            </div>
            <WithdrawForm minWithdrawValue={minWithdrawValue} fetchBalance={fetchBalance} makeWithdrawals={makeWithdrawals} />
          </ContainerSection>
        </>
      )}
      <h1 className='text-2xl font-bold text-primary mt-4'>Histórico de Saque</h1>

      <ContainerSection>
        {loadingWithdrawals ? <LoadingData /> : <WithdrawHistory dataWithdrawals={dataWithdrawals} />}
        {dataWithdrawals?.length === 0 && !loadingWithdrawals && <EmptyList message='Nenhum resultado encontrado!' />}
      </ContainerSection>
    </main>
  )
}
