import imgTest from '@/assets/images/image-withdrawal.png'

export const BlockWithdraw = () => {
  return (
    <div className='mx-auto flex flex-col justify-center items-center gap-2 p-6 mt-16 bg-zinc-100 shadow-2xl rounded-lg'>
      <img src={imgTest} alt='Imagem de saque' className='w-52 rotate-180' />
      <h1 className='font-semibold text-2xl md:text-4xl'>Saque indisponível!</h1>
      <p className='text-center'>A opção de saque estará disponível após atingir o saldo de R$ 5,00!</p>
    </div>
  )
}
