import { CpfCnpjField, PhoneField, TextField } from '@/components/Form/Fields'
import { useEffect, useState } from 'react'
import { CNPJ, CPF, EMAIL, PHONE, RANDOM_KEY } from '../constants'

export const verifyPixKeyType = formValidation => {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (!formValidation.values.pixKeyType || formValidation.values.pixKeyType < 0) {
      setDisabled(true)
      return
    }
    setDisabled(false)
  }, [formValidation.values.pixKeyType])

  switch (Number(formValidation.values.pixKeyType)) {
    case CPF:
      return (
        <CpfCnpjField
          name='pixKey'
          values={formValidation.values.pixKey}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: 123.456.789-01'
          isDisabled={disabled}
        />
      )
    case CNPJ:
      return (
        <CpfCnpjField
          name='pixKey'
          format='cnpj'
          values={formValidation.values.pixKey}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: 12.345.678/0001-90'
          isDisabled={disabled}
        />
      )
    case EMAIL:
      return (
        <TextField
          name='pixKey'
          values={formValidation.values.pixKey}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: joaosilva@email.com'
          isDisabled={disabled}
        />
      )
    case RANDOM_KEY:
      return (
        <TextField
          name='pixKey'
          values={formValidation.values.pixKey}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: db4f965d-677c-49ff-b9da-5131da1505f3'
          isDisabled={disabled}
        />
      )
    case PHONE:
      return (
        <PhoneField
          name='pixKey'
          values={formValidation.values.pixKey}
          onChange={event => formValidation.setFieldValue('pixKey', event.value)}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: (01) 912345678'
          isDisabled={disabled}
        />
      )
    default:
      return (
        <TextField
          name='pixKey'
          values={formValidation.values.pixKey}
          onChange={formValidation.handleChange}
          errorMessage={formValidation.errors.pixKey}
          label='Chave PIX'
          className='w-full'
          noMargins
          placeholder='Ex.: db4f965d-677c-49ff-b9da-5131da1505f3'
          isDisabled={disabled}
        />
      )
  }
}
