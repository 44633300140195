import { InputText, Label } from '@/components/Form'
import { FieldMessage } from '@/components/Form/Fields/FieldMessage'
import { Tooltip } from '@chakra-ui/react'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

export const TextField = ({
  name,
  onChange,
  noMargins,
  label,
  value,
  className,
  inputClassName,
  id,
  isDisabled,
  errorMessage,
  info,
  ...rest
}) => {
  const isInvalid = errorMessage ? true : false
  const labelClasses = isInvalid ? 'text-negative' : ''

  return (
    <div className={twMerge(!noMargins && 'mb-3', className)}>
      {info ? (
        <Tooltip label={info}>
          <div className='w-fit flex items-center gap-2'>
            <Label
              className={twMerge('text-sm font-medium', labelClasses)}
              text={label}
              htmlFor={id ?? name}
              isInvalid={isInvalid}
            />

            <Icon icon='gg:info' className='text-base mb-1 max-md:hidden' />
          </div>
        </Tooltip>
      ) : (
        <Label className={twMerge('text-sm font-medium', labelClasses)} text={label} htmlFor={id ?? name} isInvalid={isInvalid} />
      )}
      <InputText
        type='text'
        onChange={onChange}
        value={value}
        id={id ?? name}
        name={name}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        className={inputClassName}
        {...rest}
      />
      {isInvalid && <FieldMessage message={errorMessage} />}
      {info && (
        <span className='text-zinc-600 text-sm flex gap-1 mt-1 mb-3 items-start md:hidden'>
          <Icon icon='gg:info' className='mt-1' fontSize={18} /> {info}
        </span>
      )}
    </div>
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  noMargins: PropTypes.bool,
}

TextField.defaultProps = {
  className: '',
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
}
